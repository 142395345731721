.services {
  background-image: var(--second-gradient);
}

.swiper-wrapper {
  margin-bottom: 52px;
}

.services__container {
  text-align: center;
}

.services__subtitle {
  color: var(--title-color);
  font-size: var(--tiny-font-size);
  font-weight: 700;
  margin-bottom: 65px;
  display: block;
}

.services__title {
  font-size: var(--h4-font-size);
  margin-bottom: 15px;
}

.services__description {
  min-height: 175px;
}

.swiper-horizontal > .swiper-pagination-bullets {
  width: auto;
  position: relative;
  display: inline-block;
}

.swiper-horizontal > .swiper-pagination-bullets::before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(50%);
  height: 2px;
  width: 100%;
  background-color: var(--title-color);
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  border: 2px solid var(--title-color);
  background-color: var(--container-color);
  z-index: 1;
  position: relative;
  opacity: 1;
  margin: 0;
  transition: all 0.3s var(--transition);
}

.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet:not(:last-child) {
  margin-right: 20px;
}

.swiper-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  background-color: var(--primary-color);
  width: 13px;
  height: 13px;
  top: 2px;
}
